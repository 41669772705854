import './App.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { socket, URL } from './socket';
import { v4 as uuid } from 'uuid'
import * as jose from 'jose'

// import components 
import SideBar from './components/SideBar.js'
import TopBar from './components/TopBar.js'
import ChatList from './components/ChatList.js'
import Dashboard from './views/Dashboard.js'
import Visitors from './views/Visitors.js'
import SavedReplies from './views/SavedReplies.js';
import AutoReplies from './views/AutoReplies.js';
import Playbooks from './views/Playbooks.js';
import Installation from './views/Installation.js';
import Settings from './views/Settings.js';
import Account from './views/Account.js';
import Signin from './views/Signin.js';
import PasswordReset from './views/PasswordReset.js';


// import functions
import isSomethingUnread from './helpers/isSomethingUnread'


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

  // detect if app is opened on the mobile device with ios or android
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);




  useEffect(() => {
    const verify_token = async () => {
      try {
        if (!token) {
          setIsLoggedIn(false);
        } else {
          axios.defaults.headers.common["Authorization"] = token;
          const response = await axios.post(`${URL}/users/verify_token`);
          return response.data.ok ? login(token) : logout();
        }
      } catch (error) {
        console.log(error);
      }
    };
    verify_token();
  }, [token]);

  const login = (token) => {
    let decodedToken = jose.decodeJwt(token);
    // composing a user object based on what data we included in our token (login controller - jwt.sign() first argument)
    let user = {
      email: decodedToken.userEmail,
      userID: decodedToken.userID,
      companyID: decodedToken.companyID,
    };
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(user));
    setIsLoggedIn(true);
    setCompanyID(decodedToken.companyID);
    setToken(token);

  };


  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
  };
  // const companyName='BCS'
  const [companyID, setCompanyID] = useState(null) // company / client unique ID within the system
  // const [isConnected, setIsConnected] = useState(socket.connected); // online status
  // const [companyID, setCompanyID] = useState(null) // 
  const [companyName, setCompanyName] = useState('')
  const [user, setUser] = useState(null) // company's data
  const [chats, setChats] = useState([]) // chats with customers
  const [unreadChats, setUnreadChats] = useState(false) // for a sign that chats have unread messages 
  const [visitorTyping, setVisitorTyping] = useState(null) // for a sign that visitor is typing
  // const [visitorEmail,setVisitorEmail]=useState(null)
  // filters for unread messages 
  useEffect(() => {
    chats.length > 0 && setUnreadChats(isSomethingUnread(chats))
  }, [chats])

  // establishing a connection
  const connect = (userName, companyID) => {
    // setting user name and companyID to socket auth
    socket.auth = { userName: userName, companyID }
    // connecting to the server
    socket.connect()
  }


  // get current user from the DB with all the linked chats 
  const getUserFromDB = async (companyID) => {
    if (companyID) {
      const response = await axios.post(`${URL}/users/me`, { companyID });
      setCompanyName(response.data.data.companyName || 'no company name')
      setUser(response.data.data)
      setChats(sortChatsByDate(response.data.data.conversations))
      connect(response.data.data.userName, response.data.data.companyID)
    }
  };
  // triggering fetching data from the DB on first render 
  useEffect(() => {
    getUserFromDB(companyID)
  }, [companyID])


  // sorting chats by last update date 
  const sortChatsByDate = (chats) => {
    if (chats.length > 0) {
      let tempChats = [...chats]
      tempChats.sort((a, b) => new Date(b.conversationUpdated) - new Date(a.conversationUpdated))
      return [...tempChats]
    }
    return []
  }

  // handling socket connection error
  socket.on('connect_error', (err) => {
    if (err.message === 'missing username') {
      console.log('User is not provided')
    }
    if (err.message === 'missing company ID') {
      console.log('Company ID is not provided')
    } else {
      console.log('Connect error: ', err)
    }
  })

  // adding a visitor email to the list of chats 
  socket.on('visitorEmail', (visitorEmail, visitorID) => {
    const chatExists = chats.find(chat => chat.visitorID === visitorID)
    if (chatExists) {
      let tempChats = [...chats]
      let clientIndex = tempChats.findIndex(client => client.visitorID === visitorID)
      tempChats[clientIndex].visitorEmail = visitorEmail
      setChats([...tempChats])
    }
  })

  // handling visitor info 
  socket.on('visitorActivity', (visitorURL, lastViewdPage, visitorID, visitorIP) => {
    const chatExists = chats.find(chat => chat.visitorID === visitorID)
    if (chatExists) {
      let tempChats = [...chats]
      let clientIndex = tempChats.findIndex(client => client.visitorID === visitorID)
      tempChats[clientIndex].lastViewedURL = visitorURL
      tempChats[clientIndex].lastViewedPage = lastViewdPage
      tempChats[clientIndex].visitorLocation = visitorIP
      setChats([...tempChats])
    }
  })
  // updating visitors status in state "chats" by adding "connected" key with a value false on event visitorOffline from server
  socket.on('visitorOffline', (visitorID) => {
    const chatExists = chats.find(chat => chat.visitorID === visitorID)
    if (chatExists) {
      let tempChats = [...chats]
      let clientIndex = tempChats.findIndex(client => client.visitorID === visitorID)
      tempChats[clientIndex].connected = false
      setChats([...tempChats])
    }
  })
  socket.on('visitorOnline', (visitorID) => {
    if (chats.length > 0) {
      const chatIndex = chats.findIndex(chat => chat.visitorID === visitorID)
      if (chatIndex !== -1) {
        let tempChats = [...chats]
        tempChats[chatIndex].connected = true
        setChats([...tempChats])
      }
    }
  })
  // getting list of visitors fromserve under event onlineUsers
  socket.on('onlineUsers', (onlineUsers) => {
    if (chats.length > 0) {
      onlineUsers.forEach(onlineUser => {
        const chatIndex = chats.findIndex(chat => chat.visitorID === onlineUser.socketID)
        if (chatIndex !== -1) {
          let tempChats = [...chats]
          tempChats[chatIndex].connected = true
          setChats([...tempChats])
        }
      })
    }
  })

  // handling incoming messages from visitors and companies
  useEffect(() => {
    const onChatMessage = (msg, visitorID, socketID, type) => {
      const newMessage = {
        message: msg,
        read: false,
        timestamp: new Date(),
        type: type || 'fromVisitor',
        // _id: uuid() 
      };
      setChats(currentChats => {
        const indexOfVisitor = currentChats.findIndex(chat => chat.visitorID === visitorID);
        if (indexOfVisitor !== -1) {
          const updatedChats = [...currentChats];
          updatedChats[indexOfVisitor].visitorChat.push(newMessage);
          updatedChats[indexOfVisitor].conversationUpdated = new Date();
          updatedChats[indexOfVisitor].connected = true;
          return sortChatsByDate(updatedChats)
        } else {
          return sortChatsByDate([...currentChats, {
            connected: true,
            conversationOpen: false,
            conversationStart: new Date(),
            conversationUpdated: new Date(),
            chatClosed: false,
            socketID,
            visitorChat: [newMessage],
            visitorConversionRating: 3,
            visitorEmail: "",
            visitorHistory: [],
            visitorID,
            visitorLocation: "",
            visitorName: "",
            visitorTags: [],
            // _id: uuid()
          }])
        }
      });
    }
    socket.on('visitor to company', (message, { visitorID, socketID }) => {
      onChatMessage(message, visitorID, socketID, 'fromVisitor')
    })



    let typingTimer = null;
    socket.on('visitor typing', (userToken) => {
      setVisitorTyping(userToken);
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        setVisitorTyping(null);
      }, 3000);
    })



    socket.on('company message', (visitorID, companyName, msg) => {

      // try to find if chat exists
      const chatExists = chats.find(chat => chat.visitorID === visitorID)
      if (chatExists) {

        setChats(currentChats => {
          let tempChats = [...currentChats]
          let clientIndex = tempChats.findIndex(client => client.visitorID === visitorID)
          // tempChats[clientIndex].visitorChat.forEach(chat => chat.read = true)
          const clientChats = [...tempChats[clientIndex].visitorChat];
          clientChats.push({ type: 'toVisitor', message: msg, timestamp: new Date().toISOString(), read: true })
          tempChats[clientIndex] = { ...tempChats[clientIndex], visitorChat: clientChats };

          // instead of setting chats here i will move it into a socket incoming message to update both web and mobile dashboards 
          return ([...tempChats].sort((a, b) => new Date(b.conversationUpdated) - Date(a.conversationUpdated)))
        })
      } else {
        onChatMessage(msg, visitorID, visitorID, 'toVisitor')
      }



    })

    return () => {
      socket.off('visitor to company', onChatMessage);
    };
  }, [socket])




  return (
    <Router>
      {!isLoggedIn ?
        <div>
          <Routes>
            <Route path="/reset-password" element={<PasswordReset companyID={user?.companyID} setCompanyID={setCompanyID} login={login} />} />
            <Route path="*" element={<Signin login={login} token={token} setIsLoggedIn={setIsLoggedIn} setCompanyID={setCompanyID} />} />
          </Routes>
        </div> :
        <div className="dashboardLayout">
          <aside className='sidebar'>
            <SideBar unreadChats={unreadChats} />
          </aside>

          <Routes>
            <Route path="/" element={<Dashboard companyName={companyName} userData={user} chatsNumber={chats.length} />} />
            <Route path="/chats" element={user?.activeSubscription ? <ChatList companyName={companyName} companyID={user?.companyID} chats={chats} setChats={setChats} socket={socket} visitorTyping={visitorTyping} savedReplies={user?.savedReplies} /> : <Navigate to='/account' />} />
            <Route path="/visitors" element={<Visitors companyName={companyName} companyID={user?.companyID} chats={chats} setChats={setChats} socket={socket} userName={user?.userName} />} />
            <Route path="/savedreplies" element={<SavedReplies companyID={user?.companyID} activePlan={user?.activePlan} savedReplies={user?.savedReplies} />} />
            <Route path="/autoreplies" element={<AutoReplies companyID={user?.companyID} activePlan={user?.activePlan} autoReplies={user?.autoReplies} setUser={setUser} />} />
            <Route path="/playbooks" element={<Playbooks companyID={user?.companyID} activePlan={user?.activePlan} setUser={setUser} playbooks={user?.playbooks} />} />
            <Route path="/installation" element={<Installation companyID={user?.companyID} userEmail={user?.userEmail} />} />
            <Route path="/settings" element={<Settings companyID={user?.companyID} user={user} getUserFromDB={getUserFromDB} />} />
            <Route path="/account" element={<Account companyID={user?.companyID} setUser={setUser} logout={logout} user={user} />} />
            {/* fallback rout to send to '/' */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      }
    </Router>
  );
}

export default App;