import React from 'react';
import leChatLogoCompact from '../assets/leChat_logo.png';
import './ChatWidgetPreview.css';
import App from './preview/src/App';
const sendMsgIcon = '/images/icons/icon_send_black_transparent.png' 

const ChatWidgetPreview = ({ mainColor, accentColor, avatarImage, welcomeMessage, inputPlaceholder, leaveEmailMessage, buttonLabel, companyName, userName, emailPrompt, showLabel }) => {
    return (
    <App emailPrompt={emailPrompt} mainColor={mainColor} accentColor={accentColor} avatarImage={avatarImage} welcomeMessage={welcomeMessage} inputPlaceholder={inputPlaceholder} leaveEmailMessage={leaveEmailMessage} buttonLabel={buttonLabel} companyName={companyName} userName={userName} sendImg={sendMsgIcon} showLabel={showLabel} />
    );
}

export default ChatWidgetPreview;

