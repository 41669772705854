import { useState, useRef, useEffect } from 'react';
import './Visitors.css';
import { exportChatsToCSV } from '../helpers/exportVisitorsToCSV';

const Visitors = ({ chats, userName }) => {
    const [displayedChatsClosed, setDisplayedChatsClosed] = useState(false);
    const [sortedChats, setSortedChats] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showOnlyWithEmail, setShowOnlyWithEmail] = useState(false);

    // dropdown menu
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setSortedChats(chats);
    }, [chats]);

    const sortedFilteredChats = showOnlyWithEmail
        ? sortedChats.filter(chat => chat.visitorEmail)
        : sortedChats;

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedArray = [...sortedChats].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setSortedChats(sortedArray);
    };

    const toggleFilterWithEmail = () => {
        setShowOnlyWithEmail(prevState => !prevState);
    };

    const getSortArrow = (key) => {
        if (sortConfig.key !== key) return '↓';
        return sortConfig.direction === 'asc' ? '↑' : '↓';
    };

    return (
        <>
            {chats ?
                <section className='outlined homeContainer'>
                    <div className='underlined inlineFlexContainer'>
                        <h1>How are you doing, <span className='boldText'>{userName}</span></h1>

                        <div ref={dropdownRef} style={styles.dropdownContainer}>
                            <button onClick={toggleDropdown} style={styles.dropdownButton}>
                                <span className='tinyText' style={{ ...styles.arrow, transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                                    ▽
                                </span>
                            </button>
                            {isOpen && (
                                <div className='tinyText' style={styles.dropdownMenu}>
                                    <div
                                        onClick={() => exportChatsToCSV(chats)}
                                        style={styles.dropdownItem}
                                    >
                                        Export users
                                    </div>
                                    <div
                                        onClick={toggleFilterWithEmail}
                                        style={styles.dropdownItem}
                                    >
                                        {showOnlyWithEmail ? 'Show All Users' : 'Show Only Users with Email'}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <p>Here you will see the visitors who have contacted you via Le Chat</p>
                    <article className='bubbleContainer'>
                        <div>
                            <section className='visitorsTable'>
                                <p className='headerP' onClick={() => handleSort('visitorID')}>ID</p>
                                <p className='headerP' onClick={() => handleSort('visitorEmail')}>Email {getSortArrow('visitorEmail')}</p>
                                <p className='headerP' onClick={() => handleSort('visitorLocation')}>Location {getSortArrow('visitorLocation')}</p>
                                <p className='headerP'>Tags</p>
                                <p className='headerP'>History</p>
                                <p className='headerP' onClick={() => handleSort('conversationStart')}>Conversation started {getSortArrow('conversationStart')}</p>
                                <p className='headerP' onClick={() => handleSort('visitorConversionRating')}>Conversion Rating {getSortArrow('visitorConversionRating')}</p>
                            </section>
                            {sortedFilteredChats.map((visitor) => (
                                <section className='visitorsTable' key={visitor.visitorID}>
                                    <p className='tinyText singleLineEllipsisCut'>{visitor.visitorID}</p>
                                    <p>{visitor.visitorEmail}</p>
                                    <p>{!visitor.visitorLocation.includes('undefined') ? visitor.visitorLocation : 'Not shared'}</p>
                                    <p>{visitor.visitorTags}</p>
                                    <p>{visitor.visitorHistory}</p>
                                    <p>{new Date(visitor.conversationStart).toDateString()}</p>
                                    <p>{visitor.visitorConversionRating}</p>
                                </section>
                            ))}
                        </div>
                    </article>
                </section> :
                <section className='outlined homeContainer'>
                    <h1 className='underlined'>Loading...</h1>
                </section>}
        </>
    );
};

export default Visitors;

const styles = {
    dropdownContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    dropdownButton: {
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '5px 10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
        zIndex: '1000',
        width: 'max-content',
        margin: 0,
    },
    arrow: {
        display: 'inline-block',
        transition: 'transform 0.3s ease',
    },
    dropdownMenu: {
        position: 'absolute',
        top: '100%',
        right: '0',
        width: 'max-content',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #ccc',
    },
};
