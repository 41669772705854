import { useState, useEffect } from 'react';
import './Playbooks.css';
import subscriptionPlans from '../static/subscriptionPlans';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { URL } from '../socket';
const trashCanimage = '/images/icons/icon-delete.png';
const editIcon = '/images/icons/icon-edit.png';
const saveIcon = '/images/icons/icon-save.png';

export default function Playbooks({ companyID, activePlan, playbooks, setUser }) {
	const navigate = useNavigate();
	const [newPlaybookMessage, setNewPlaybookMessage] = useState('');
	const [urlEnabled, setUrlEnabled] = useState(false);
	const [currentURL, setCurrentURL] = useState('');
	const [triggerSeconds, setTriggerSeconds] = useState(0);
	const [editPlaybook, setEditPlaybook] = useState(null);
	const [editPlaybookMessage, setEditPlaybookMessage] = useState('');
	const [editTriggerSeconds, setEditTriggerSeconds] = useState(0);
	const [editURL, setEditURL] = useState('');
	const [editUrlEnabled, setEditUrlEnabled] = useState(false);

	useEffect(() => {
		if (companyID) {
			// Assuming playbooks are passed as a prop to this component
			setUser((user) => ({ ...user, playbooks: playbooks || [] }));
		}
	}, [companyID, playbooks, setUser]);

	const handleSavePlaybook = async (e) => {
		e.preventDefault();
		if (!newPlaybookMessage.trim() || triggerSeconds <= 0) return;

		const playbook = {
			id: uuidv4(),
			playbookMessage: newPlaybookMessage,
			triggerSeconds,
			url: urlEnabled ? currentURL : null,
			enable: true,
		};
		setUser((user) => ({ ...user, playbooks: [...playbooks, playbook] }));
		try {
			const response = await axios.post(`${URL}/users/save-playbook`, { companyID, playbook });
			if (response.data.ok) {
				setNewPlaybookMessage('');
				setTriggerSeconds(0);
				setCurrentURL('');
				setUrlEnabled(false);
			}
		} catch (error) {
			console.error('Error saving playbook:', error);
		}
	};

	const handleTogglePlaybook = async (id) => {
		const updatedPlaybooks = playbooks.map(playbook => {
			if (playbook.id === id) {
				return { ...playbook, enable: !playbook.enable };
			}
			return playbook;
		});
		setUser((user) => ({ ...user, playbooks: updatedPlaybooks }));

		try {
			await axios.post(`${URL}/users/toggle-playbook`, { companyID, id });
		} catch (error) {
			console.error('Error toggling playbook:', error);
		}
	};

	const handleEditPlaybook = (playbook) => {
		setEditPlaybook(playbook.id);
		setEditPlaybookMessage(playbook.playbookMessage);
		setEditTriggerSeconds(playbook.triggerSeconds);
		setEditURL(playbook.url || '');
		setEditUrlEnabled(!!playbook.url);
	};

	const handleSaveEdit = async (id) => {
		const updatedPlaybooks = playbooks.map(playbook => {
			if (playbook.id === id) {
				return {
					...playbook,
					playbookMessage: editPlaybookMessage,
					triggerSeconds: editTriggerSeconds,
					url: editUrlEnabled ? editURL : null
				};
			}
			return playbook;
		});
		setUser((user) => ({ ...user, playbooks: updatedPlaybooks }));
		setEditPlaybook(null);
		setEditPlaybookMessage('');
		setEditTriggerSeconds(0);
		setEditURL('');
		setEditUrlEnabled(false);

		try {
			await axios.post(`${URL}/users/edit-playbook`, { companyID, id, playbookMessage: editPlaybookMessage, triggerSeconds: editTriggerSeconds, url: editUrlEnabled ? editURL : null });
		} catch (error) {
			console.error('Error saving edited playbook:', error);
		}
	};

	const handleRemovePlaybook = async (id) => {
		try {
			const response = await axios.post(`${URL}/users/delete-playbook`, { companyID, id });
			if (response.data.ok) {
				const updatedPlaybooks = playbooks.filter(playbook => playbook.id !== id);
				setUser((user) => ({ ...user, playbooks: updatedPlaybooks }));
			}
		} catch (error) {
			console.error('Error removing playbook:', error);
		}
	};

	const renderPlaybooks = (playbooks, isEnabled) => {
		return playbooks.filter(playbook => playbook.enable === isEnabled).map(playbook => (
			<div key={playbook.id} className='bubble playbookItem'>
				{editPlaybook === playbook.id ? (
					<>
						<textarea
							value={editPlaybookMessage}
							onChange={(e) => setEditPlaybookMessage(e.target.value)}
						/>
						<div>
							<label>
								Trigger Seconds:
								<input
									type="number"
									value={editTriggerSeconds}
									onChange={(e) => setEditTriggerSeconds(e.target.value)}
								/>
							</label>
						</div>
						<div>
							<label>
								<input
									type="checkbox"
									checked={editUrlEnabled}
									onChange={() => setEditUrlEnabled(!editUrlEnabled)}
								/>
								Enable URL Matching
							</label>
							{editUrlEnabled && (
								<input
									type="text"
									value={editURL}
									onChange={(e) => setEditURL(e.target.value)}
									placeholder="Enter URL"
								/>
							)}
						</div>
						<img className='uiIcon' src={saveIcon} alt='save' onClick={() => handleSaveEdit(playbook.id)} />
					</>
				) : (
					<>
						<p><strong>Message:</strong> {playbook.playbookMessage}</p>
						<p><strong>Trigger Seconds:</strong> {playbook.triggerSeconds}</p>
						{playbook.url && <p><strong>URL contains:</strong> {playbook.url}</p>}
						<div>
							<input
								type="checkbox"
								checked={playbook.enable}
								onChange={() => handleTogglePlaybook(playbook.id)}
							/>
							<span>Enabled</span>
							<img className='uiIcon' src={editIcon} alt='edit' onClick={() => handleEditPlaybook(playbook)} />
							<img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => handleRemovePlaybook(playbook.id)} />
						</div>
					</>
				)}
			</div>
		));
	};

	return (
		<>
			{companyID ? (
				<section className='outlined homeContainer' id='playBooks'>
					<h1 className='underlined'>Playbooks panel</h1>
					<p className='bottomSpacer'>Here you can work with your Playbooks scenarios.</p>
					<p>A playbook will proactively open a chat widget on your website and show the visitor a message defined by you. You can set when to trigger a playbook (after a visitor spent a certain number of seconds on your website or at a specific URL).</p>
					{activePlan < 2 ? (
						<article className='bubbleContainer'>
							<p>Not available for your subscription plan...</p>
							<p>You active plan is {subscriptionPlans[activePlan]}</p>
							<p onClick={() => navigate('/account')}>
								You can upgrade to another plan from your{' '}
								<span className='boldText underlined'>Account</span> section
							</p>
						</article>
					) : (
						<>
							<article className='bubbleContainer'>
								<h2>Enabled Playbooks</h2>
								{renderPlaybooks(playbooks, true)}
							</article>
							<article className='bubbleContainer'>
								<h2>Draft Playbooks</h2>
								{renderPlaybooks(playbooks, false)}
							</article>
							<article className='bubbleContainer'>
								<div className='bubble'>
									<h2>New Playbook</h2>
									<form onSubmit={handleSavePlaybook}>
										<textarea
											value={newPlaybookMessage}
											onChange={(e) => setNewPlaybookMessage(e.target.value)}
											placeholder="Type your new playbook message here..."
										/>
										<div>
											<label>
												Trigger Seconds:
												<input
													type="number"
													value={triggerSeconds}
													onChange={(e) => setTriggerSeconds(e.target.value)}
												/>
											</label>
										</div>
										<div>
											<label>
												<input
													type="checkbox"
													checked={urlEnabled}
													onChange={() => setUrlEnabled(!urlEnabled)}
												/>
												Enable URL matching
											</label>
											{urlEnabled && (
												<input
													type="text"
													value={currentURL}
													onChange={(e) => setCurrentURL(e.target.value)}
													placeholder="Enter URL"
												/>
											)}
										</div>
										<button type="submit" className='saveButton' disabled={newPlaybookMessage.length < 1 || triggerSeconds <= 0}>
											Save Playbook
										</button>
									</form>
								</div>
							</article>
						</>
					)}
				</section>
			) : (
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Loading...</h1>
				</section>
			)}
		</>
	);
}
