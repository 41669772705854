import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SavedReplies.css';
import subscriptionPlans from '../static/subscriptionPlans';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { URL } from '../socket';
const trashCanimage = '/images/icons/icon-delete.png';
const editIcon = '/images/icons/icon-edit.png';
const saveIcon = '/images/icons/icon-save.png';

export default function SavedReplies({ companyID, activePlan, savedReplies }) {
    const navigate = useNavigate();
    const [newReply, setNewReply] = useState('');
    const [replies, setReplies] = useState([]);
    const [isDraft, setIsDraft] = useState(false);
    const [editReply, setEditReply] = useState(null);
    const [editReplyMessage, setEditReplyMessage] = useState('');

    useEffect(() => {
        if (companyID) {
            setReplies(savedReplies);
        }
    }, [companyID, savedReplies]);

    const handleSaveReply = async (e) => {
        e.preventDefault();
        if (!newReply.trim()) return;

        const reply = {
            replyName: uuidv4(),
            replyMessage: newReply,
            enable: !isDraft,
        };

        try {
            const response = await axios.post(`${URL}/users/save-reply`, { companyID, reply });
            if (response.data.ok) {
                setReplies([...replies, reply]);
                setNewReply('');
                setIsDraft(false);
            }
        } catch (error) {
            console.error('Error saving reply:', error);
        }
    };

    const handleToggleReply = async (replyName) => {
        const updatedReplies = replies.map(reply => {
            if (reply.replyName === replyName) {
                return { ...reply, enable: !reply.enable };
            }
            return reply;
        });
        setReplies(updatedReplies);
        try {
            await axios.post(`${URL}/users/toggle-reply`, { companyID, replyName });
        } catch (error) {
            console.error('Error toggling reply:', error);
        }
    };

    const deleteReply = async (replyName) => {
        const updatedReplies = replies.filter(reply => reply.replyName !== replyName);
        setReplies(updatedReplies);

        try {
            const response = await axios.post(`${URL}/users/delete-reply`, { companyID, replyName });
            if (!response.data.ok) {
                console.error('Error deleting reply from the server:', response.data.error);
            }
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

    const handleEditReply = (reply) => {
        setEditReply(reply.replyName);
        setEditReplyMessage(reply.replyMessage);
    };

    const handleSaveEdit = async (replyName) => {
        const updatedReplies = replies.map(reply => {
            if (reply.replyName === replyName) {
                return { ...reply, replyMessage: editReplyMessage };
            }
            return reply;
        });
        setReplies(updatedReplies);
        setEditReply(null);
        setEditReplyMessage('');

        try {
            await axios.post(`${URL}/users/edit-reply`, { companyID, replyName, replyMessage: editReplyMessage });
        } catch (error) {
            console.error('Error saving edited reply:', error);
        }
    };

    return (
        <>
            {companyID ? (
                <section className='outlined homeContainer' id='savedReplies'>
                    <h1 className='underlined'>Saved replies panel</h1>
                    <p>Here you can work with your saved replies.</p>
                    {activePlan < 2 ? (
                        <article className='bubbleContainer'>
                            <p>Not available for your subscription plan...</p>
                            <p>You active plan is {subscriptionPlans[activePlan]}</p>
                            <p onClick={() => navigate('/account')}>
                                You can upgrade to another plan from your{' '}
                                <span className='boldText underlined'>Account</span> section
                            </p>
                        </article>
                    ) : (
                        <>
                            <article className='bubbleContainer'>
                                <p>Enabled Saved Replies</p>
                                {replies.filter(reply => reply.enable).map(reply => (
                                    <div key={reply.replyName} className='bubble'>
                                        {editReply === reply.replyName ? (
                                            <>
                                                <textarea
                                                    value={editReplyMessage}
                                                    onChange={(e) => setEditReplyMessage(e.target.value)}
                                                />
                                                <img className='uiIcon' src={saveIcon} alt='save' onClick={() => handleSaveEdit(reply.replyName)} />
                                            </>
                                        ) : (
                                            <>
                                                <p className='singleChatMessage'>{reply.replyMessage}</p>
                                                <input
                                                    type="checkbox"
                                                    checked={reply.enable}
                                                    onChange={() => handleToggleReply(reply.replyName)}
                                                />
                                                <span>Enabled</span>
                                                <img className='uiIcon' src={editIcon} alt='edit' onClick={() => handleEditReply(reply)} />
                                                <img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => deleteReply(reply.replyName)} />
                                            </>
                                        )}
                                    </div>
                                ))}
                            </article>
                            <article className='bubbleContainer'>
                                <p>Draft Saved Replies</p>
                                {replies.filter(reply => !reply.enable).map(reply => (
                                    <div key={reply.replyName} className='bubble'>
                                        {editReply === reply.replyName ? (
                                            <>
                                                <textarea
                                                    value={editReplyMessage}
                                                    onChange={(e) => setEditReplyMessage(e.target.value)}
                                                />
                                                <img className='uiIcon' src={saveIcon} alt='save' onClick={() => handleSaveEdit(reply.replyName)} />
                                            </>
                                        ) : (
                                            <>
                                                <p className='singleChatMessage'>{reply.replyMessage}</p>
                                                <input
                                                    type="checkbox"
                                                    checked={reply.enable}
                                                    onChange={() => handleToggleReply(reply.replyName)}
                                                />
                                                <span>Enabled</span>
                                                <img className='uiIcon' src={editIcon} alt='edit' onClick={() => handleEditReply(reply)} />
                                                <img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => deleteReply(reply.replyName)} />
                                            </>
                                        )}
                                    </div>
                                ))}
                            </article>
                            <article className='bubbleContainer'>
                                <div className='bubble'>
                                    <h1>New Saved Reply</h1>
                                    <form onSubmit={handleSaveReply}>
                                        <textarea
                                            value={newReply}
                                            onChange={(e) => setNewReply(e.target.value)}
                                            placeholder="Type your new reply here..."
                                        />
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={isDraft}
                                                    onChange={() => setIsDraft(!isDraft)}
                                                />
                                                Save as Draft
                                            </label>
                                        </div>
                                        <button type="submit" className='saveButton' disabled={newReply.length < 1}>Save Reply</button>
                                    </form>
                                </div>
                            </article>
                        </>
                    )}
                </section>
            ) : (
                <section className='outlined homeContainer'>
                    <h1 className='underlined'>Loading...</h1>
                </section>
            )}
        </>
    );
}
