export const exportChatsToCSV = (chats) => {
    const replacer = (key, value) => value === null ? '' : value;
    const header = ['visitorID', 'visitorEmail', 'visitorLocation', 'visitorTags', 'visitorHistory', 'conversationStart', 'visitorConversionRating'];
    let csv = chats.map(row => {
        const modifiedLocation = row.visitorLocation.includes('undefined') ? 'Not shared' : row.visitorLocation;
        // const modifiedName = row.visitorName ? row.visitorName : 'Not provided'; // Replace empty visitor name with "Not provided"
        const modifiedConversationStart = new Date(row.conversationStart).toLocaleString(); // Convert conversationStart to human readable format
        return header.map(fieldName => JSON.stringify(fieldName === 'visitorLocation' ? modifiedLocation : fieldName === 'conversationStart' ? modifiedConversationStart : row[fieldName], replacer)).join(',');
    });
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'leChat_visitors.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}