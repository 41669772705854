import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../socket'
import subscriptionPlans from '../static/subscriptionPlans';
// import './App.css';
const PRICE_LOOKUP_KEY = 'justChat'

const ProductDisplay = ({ userEmail }) => (
  <section>
    <div className="product">
      <div className="description">
        <h3>Just Chat</h3>
        <h5>€19.99 / month</h5>
      </div>
    </div>
    <form action={`${URL}/subscriptions/create-checkout-session`} method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value={PRICE_LOOKUP_KEY} />
      <input type="hidden" name="email" value={userEmail} />
      <button id="checkout-and-portal-button" type="submit" className='saveButton'>
        Subscribe
      </button>
    </form>
  </section>
);

const SuccessDisplay = ({ customerID }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to {subscriptionPlans[1]} plan successful!</h3>
        </div>
      </div>
      <form action={`${URL}/subscriptions/create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="customerID"
          value={customerID}
        />
        {/* <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        /> */}
        <button id="checkout-and-portal-button" type="submit">
          Manage your subscription and billing info
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);



export default function JustChat({ userEmail, setUser, subscriptionPlan, customerID,subscriptionStatus }) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(true);
  let [sessionId, setSessionId] = useState('');
  let [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success') && query.get('plan')==='justChat') {
      setSuccess(true);
      let session_id = query.get('session_id');
      setSessionId(session_id);
      // here we know that customer subscribed
      // we need to get the customer ID from stripe now
      // and save it in the database
      const saveStripeCustomerId = async () => {
        try {
          let customerStripeData = await axios.post(`${URL}/subscriptions/save-stripe-customer-id`, { session_id, userEmail,plan:1 });
          // let { created, customer, current_period_end } = customerStripeData.data.data
          let { activePlan,subscription, activeSubscription } = customerStripeData.data
          setUser((prevUser) => ({ ...prevUser, subscription, activePlan, activeSubscription })
          )
        } catch (e) {
          console.log(e);
        }
      };
      setQueryParams('')
      saveStripeCustomerId();
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '' && userEmail && !subscriptionStatus) {
    return <ProductDisplay userEmail={userEmail} />;
  } else if ((success && sessionId !== '' && customerID && subscriptionStatus) || (subscriptionPlan === 1 && customerID && subscriptionStatus)) {
    return <SuccessDisplay customerID={customerID} />;
  } else if((success && sessionId !== '' && customerID && subscriptionStatus) || (subscriptionPlan > 1 && customerID && subscriptionStatus)){
    return null
  }else {
    // return <Message message={message} />;
    return <ProductDisplay userEmail={userEmail} />
  }
}

