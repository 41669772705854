import './Installation.css'

export default function Installation({ companyID, userEmail }) {
	const installSnippet = ` <link rel="stylesheet" href="https://dashboard.lechat.app/assets/widget/styles.css">
 <script>
  function initializeWidget() {
   new LeChatWidget({
    companyName: "${companyID}"
   });
  }
 </script>
 <script src="https://dashboard.lechat.app/assets/widget/lechat.js" onload="initializeWidget()"></script>`

	const exampleIndexHTML = `<!DOCTYPE html>
 <html>
 <head>
	 <meta charset="utf-8">
	 <meta name="viewport" content="width=device-width, initial-scale=1">
	 <title>My website</title>
 
 </head>
 <body>
	 <div id="root"></div>
	 <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
	tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
	quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
	consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
	cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
	proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
 </body>
 </html>`

	const exampleIndexHTMLWithSnippet = `<!DOCTYPE html>
	<html>
	<head>
		<meta charset="utf-8">
		<meta name="viewport" content="width=device-width, initial-scale=1">
		<title>My website</title>
	</head>
	<body>
		<div id="root"></div>
		<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
		tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
		consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
		cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
		proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
		</body>
		
		<link rel="stylesheet" href="https://dashboard.lechat.app/assets/widget/styles.css">
		<script>
			function initializeWidget() {
				new LeChatWidget({
					companyName: "${companyID}"
				});
			}
		</script>
		<script src="https://dashboard.lechat.app/assets/widget/lechat.js" onload="initializeWidget()"></script>

		</html>`

	return (
		<>
			{companyID ?
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Installation panel</h1>
					<p>Installing your website chat widget.</p>
					<article className='bubbleContainer'>

						<details>
							<summary><h2>Manual install</h2><p>Add code snippet to your website</p><p className='tinyText boldText'>See how...</p></summary>
							<p>To install the widget to your website you will need to add the following code into the <pre className='inlineElement'>index.html</pre> file just before the closing <pre className='inlineElement'>{`</html>`}</pre> tag.</p>

							<pre style={styles.snippet}>{installSnippet}</pre>

							<p>For example, if this would be your website <pre className='inlineElement'>index.html</pre> file:</p>

							<pre style={styles.snippet}>{exampleIndexHTML}</pre>

							<p>After adding the snippet, your <pre className='inlineElement'>index.html</pre> file should look like this:</p>

							<pre style={styles.snippet}>{exampleIndexHTMLWithSnippet}</pre>
						</details>


					</article>
					<article className='bubbleContainer'>
						<details>
							<summary><h2>Wordpress</h2><p>Use Le Chat plugin for WordPress</p><p className='tinyText boldText'>See how...</p></summary>
							<p>If you want to install Le Chat to a WordPress website please <a href='https://dashboard.lechat.app/assets/widget/lechat-widget.zip'>download Le Chat WP plugin</a></p>
							<ol>
								<li>Install the plugin from your WordPress website</li>
								<li>Activate plugin</li>
								<li>In the sidebar you will have a new item "Le Chat Widget", click on it</li>
								<li>Login with your Le Chat credentials ({userEmail} and password you used to register for our service) in the plugin settings page</li>
								<li>The plugin is activated and ready for work. You should see Le Chat widget in your website now.</li>
							</ol>
							<br/>
							<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/984832949?h=b092f5c27a" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', maxWidth:'500px', maxHeight:'282px'}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
						</details>

					</article>
				</section> :
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Loading...</h1>
				</section>
			}
		</>
	)
}
const styles = {
	snippet: {
		backgroundColor: 'rgba(0,0,0,0.1)',
		padding: '2em 1em',
		borderRadius: '5px',
		overflow: 'auto',
	}
}