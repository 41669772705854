import axios from 'axios'
import { URL } from '../socket';

const updateChats = async (companyID,tempChats) => {
	try {
		const response = await axios.post(`${URL}/chats/updateChats`, {companyID,allChats:tempChats})
		if(response.data.ok){
		}
		return
	}
	catch(e){
		console.log({e})
	}
}

export default updateChats
