import React, { useState } from 'react';
import './AutoReplies.css';
import subscriptionPlans from '../static/subscriptionPlans';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { URL } from '../socket';
const trashCanimage = '/images/icons/icon-delete.png';
const editIcon = '/images/icons/icon-edit.png';
const saveIcon = '/images/icons/icon-save.png';

export default function AutoReplies({ companyID, activePlan, autoReplies, setUser }) {
    const navigate = useNavigate();
    const [newAutoReply, setNewAutoReply] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [currentKeyword, setCurrentKeyword] = useState('');
    const [currentURL, setCurrentURL] = useState('');
    const [urlEnabled, setUrlEnabled] = useState(false);
    const [editReply, setEditReply] = useState(null);
    const [editReplyMessage, setEditReplyMessage] = useState('');
    const [editKeywords, setEditKeywords] = useState([]);
    const [editURL, setEditURL] = useState('');
    const [editUrlEnabled, setEditUrlEnabled] = useState(false);

    const handleAddKeyword = () => {
        if (currentKeyword.trim()) {
            setKeywords([...keywords, currentKeyword.trim()]);
            setCurrentKeyword('');
        }
    };

    const handleRemoveKeyword = (keyword) => {
        setKeywords(keywords.filter(k => k !== keyword));
    };

    const handleSaveAutoReply = async (e) => {
        e.preventDefault();
        if (!newAutoReply.trim()) return;

        const autoReply = {
            id: uuidv4(),
            replyMessage: newAutoReply,
            keywords: keywords,
            url: urlEnabled ? currentURL : null,
            enable: true,
        };
        setUser((user) => ({ ...user, autoReplies: [...autoReplies, autoReply] }));
        try {
            const response = await axios.post(`${URL}/users/save-auto-reply`, { companyID, autoReply });
            if (response.data.ok) {
                setNewAutoReply('');
                setKeywords([]);
                setCurrentURL('');
                setUrlEnabled(false);
            }
        } catch (error) {
            console.error('Error saving auto reply:', error);
        }
    };

    const handleToggleAutoReply = async (id) => {
        const updatedAutoReplies = autoReplies.map(reply => {
            if (reply.id === id) {
                return { ...reply, enable: !reply.enable };
            }
            return reply;
        });
        setUser((user) => ({ ...user, autoReplies: updatedAutoReplies }));

        try {
            await axios.post(`${URL}/users/toggle-auto-reply`, { companyID, id });
        } catch (error) {
            console.error('Error toggling auto reply:', error);
        }
    };

    const handleEditReply = (reply) => {
        setEditReply(reply.id);
        setEditReplyMessage(reply.replyMessage);
        setEditKeywords(reply.keywords);
        setEditURL(reply.url || '');
        setEditUrlEnabled(!!reply.url);
    };

    const handleSaveEdit = async (id) => {
        const updatedReplies = autoReplies.map(reply => {
            if (reply.id === id) {
                return {
                    ...reply,
                    replyMessage: editReplyMessage,
                    keywords: editKeywords,
                    url: editUrlEnabled ? editURL : null
                };
            }
            return reply;
        });
        setUser((user) => ({ ...user, autoReplies: updatedReplies }));
        setEditReply(null);
        setEditReplyMessage('');
        setEditKeywords([]);
        setEditURL('');
        setEditUrlEnabled(false);

        try {
            await axios.post(`${URL}/users/edit-auto-reply`, { companyID, id, replyMessage: editReplyMessage, keywords: editKeywords, url: editUrlEnabled ? editURL : null });
        } catch (error) {
            console.error('Error saving edited auto reply:', error);
        }
    };

    const handleRemoveAutoReply = async (id) => {
        try {
            const response = await axios.post(`${URL}/users/delete-auto-reply`, { companyID, id });
            if (response.data.ok) {
                const updatedAutoReplies = autoReplies.filter(reply => reply.id !== id);
                setUser((user) => ({ ...user, autoReplies: updatedAutoReplies }));
            }
        } catch (error) {
            console.error('Error removing auto reply:', error);
        }
    };

    const renderAutoReplies = (replies, isEnabled) => {
        return replies.filter(reply => reply.enable === isEnabled).map(reply => (
            <div key={reply.id} className='bubble autoReplyItem'>
                {editReply === reply.id ? (
                    <>
                        <textarea
                            value={editReplyMessage}
                            onChange={(e) => setEditReplyMessage(e.target.value)}
                        />
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={editUrlEnabled}
                                    onChange={() => setEditUrlEnabled(!editUrlEnabled)}
                                />
                                Enable URL matching
                            </label>
                            {editUrlEnabled && (
                                <input
                                    type="text"
                                    value={editURL}
                                    onChange={(e) => setEditURL(e.target.value)}
                                    placeholder="Enter URL"
                                />
                            )}
                        </div>
                        <div className='keywordInput'>
                            <input
                                type="text"
                                value={currentKeyword}
                                onChange={(e) => setCurrentKeyword(e.target.value)}
                                placeholder="Enter keyword"
                            />
                            <button type="button" onClick={() => setEditKeywords([...editKeywords, currentKeyword])}>
                                Add Keyword
                            </button>
                        </div>
                        <div>
                            {editKeywords.map((keyword, index) => (
                                <span key={index} className='keyword'>
                                    {keyword}
                                    <img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => setEditKeywords(editKeywords.filter(k => k !== keyword))} />
                                </span>
                            ))}
                        </div>
                        <img className='uiIcon' src={saveIcon} alt='save' onClick={() => handleSaveEdit(reply.id)} />
                    </>
                ) : (
                    <>
                        <p><strong>Reply:</strong> {reply.replyMessage}</p>
                        <p><strong>Keywords:</strong> {reply.keywords.join(', ')}</p>
                        {reply.url && <p><strong>URL contains:</strong> {reply.url}</p>}
                        <div>
                            <input
                                type="checkbox"
                                checked={reply.enable}
                                onChange={() => handleToggleAutoReply(reply.id)}
                            />
                            <span>Enabled</span>
                            <img className='uiIcon' src={editIcon} alt='edit' onClick={() => handleEditReply(reply)} />
                            <img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => handleRemoveAutoReply(reply.id)} />
                        </div>
                    </>
                )}
            </div>
        ));
    };

    return (
        <>
            {companyID ? (
                <section className='outlined homeContainer' id='autoReplies'>
                    <h1 className='underlined'>Automatic replies panel</h1>
                    <p>Here you can work with your automatic replies.</p>
                    {activePlan < 2 ? (
                        <article className='bubbleContainer'>
                            <p>Not available for your subscription plan...</p>
                            <p>Your active plan is {subscriptionPlans[activePlan]}</p>
                            <p onClick={() => navigate('/account')}>
                                You can upgrade to another plan from your{' '}
                                <span className='boldText underlined'>Account</span> section
                            </p>
                        </article>
                    ) : (
                        <>
                            <article className='bubbleContainer'>
                                <h2>Enabled Automatic Replies</h2>
                                {renderAutoReplies(autoReplies, true)}
                            </article>
                            <article className='bubbleContainer'>
                                <h2>Draft Automatic Replies</h2>
                                {renderAutoReplies(autoReplies, false)}
                            </article>
                            <article className='bubbleContainer'>
                                <div className='bubble'>
                                    <h2>New Automatic Reply</h2>
                                    <form onSubmit={handleSaveAutoReply}>
                                        <textarea
                                            value={newAutoReply}
                                            onChange={(e) => setNewAutoReply(e.target.value)}
                                            placeholder="Type your new automatic reply here..."
                                        />
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={urlEnabled}
                                                    onChange={() => setUrlEnabled(!urlEnabled)}
                                                />
                                                Enable URL matching
                                            </label>
                                            {urlEnabled && (
                                                <input
                                                    type="text"
                                                    value={currentURL}
                                                    onChange={(e) => setCurrentURL(e.target.value)}
                                                    placeholder="Enter URL"
                                                />
                                            )}
                                        </div>
                                        <div className='keywordInput'>
                                            <input
                                                type="text"
                                                value={currentKeyword}
                                                onChange={(e) => setCurrentKeyword(e.target.value)}
                                                placeholder="Enter keyword"
                                            />
                                            <button type="button" onClick={handleAddKeyword}>Add Keyword</button>
                                        </div>
                                            <p className='bottomSpacer'>Keywords will be used with "or" logic. For example, if you add "banana" and "kiwi" the auto reply will trigger for messages conataining either banana or kiwi or both.</p>
                                        <div>
                                            {keywords.map((keyword, index) => (
                                                <span key={index} className='keyword'>
                                                    {keyword}
                                                    <img className='uiIcon' src={trashCanimage} alt='delete' onClick={() => handleRemoveKeyword(keyword)} />
                                                </span>
                                            ))}
                                        </div>
                                        <button type="submit" className='saveButton' disabled={newAutoReply.length < 1}>
                                            Save Auto Reply
                                        </button>
                                    </form>
                                </div>
                            </article>
                        </>
                    )}
                </section>
            ) : (
                <section className='outlined homeContainer'>
                    <h1 className='underlined'>Loading...</h1>
                </section>
            )}
        </>
    );
}
