import './PasswordReset.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import validator from 'validator';
import { v4 as uuid } from 'uuid'
import { URL } from '../socket'
import * as jose from 'jose'

import { useNavigate } from 'react-router-dom'

import CenteredLogo from '../components/CenteredLogo';

const PasswordReset = ({ login, setCompanyID }) => {
    const [mode, setMode] = useState('request') // reset or request

    useEffect(() => {
        // check if there are email and key queries in the url
        if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            const email = urlParams.get('email');
            const key = urlParams.get('key');
            if (email && key) {
                setMode('reset')
                setKey(key)
                setEmail(email.toLowerCase())
            } else {
                setMode('request')
            }
        }
    }, [])

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConf, setPasswordConf] = useState('')
    const [newPasswordsOK, setNewPasswordsOK] = useState(false)
    const [requestDetailsOk, setRequestDetailsOk] = useState(false)
    const [key, setKey] = useState('')

    const [error, setError] = useState(null)

    const navigate = useNavigate()



    useEffect(() => {
        if (password === passwordConf && password.length > 7 && password.match(/[0-9]/) && password.match(/[!@#$%^&*]/) ) {
            setNewPasswordsOK(true)
        } else {
            setNewPasswordsOK(false)
        }
    }, [password, passwordConf])

    useEffect(() => {
        if (validator.isEmail(email)) {
            setRequestDetailsOk(true)
        } else {
            setRequestDetailsOk(false)
        }
    }, [email])

    const loginfAuth = async (email, password) => {
        try {
            const response = await axios.post(`${URL}/users/login`, { emailLogin: email, passwordLogin: password })
            if (response.data.ok) {
                let decodedToken = jose.decodeJwt(response.data.token);
                setCompanyID(response.data.companyID)
                login(response.data.token);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const reqPasswordReset = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${URL}/users/resetPassword`, { email })
            if (response.data.ok) {
                alert('An email has been sent to you with the instructions to reset your password')
                // navigate('/')
            }
        } catch (error) {
            alert('There was an error, please try again')
            console.log(error);
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${URL}/users/setNewPassword`, {
                email, password, key });
            if (response.data.ok) {
                loginfAuth(email, password)
            } else if (response.data.message === 'User exists!') {
                alert('This user already exists, please try to login instead.')
                setPassword('')
                setPasswordConf('')
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <section className="passwordReset">
            <CenteredLogo />
            {mode === 'request' ?
                <div className='bubbleContainer'>
                    <form onSubmit={reqPasswordReset}>
                        <h1>Reset password</h1>
                        <p>Please enter the email you use for your sign in.</p>
                        <p>You will receive an email with the steps to reset the password.</p>
                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                        <button disabled={!requestDetailsOk} className='saveButton' >Confirm email</button>
                    </form>
                </div> :
                <div className='bubbleContainer'>
                    <form onSubmit={updatePassword}>
                        <h1>Create new password</h1>
                        <p>Please use a strong password, at least 8 characters long, should contain at least 1 number and 1 special character</p>
                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <input type="password" placeholder="Confirm password" value={passwordConf} onChange={(e) => setPasswordConf(e.target.value)} />
                        <button disabled={!newPasswordsOK} type="submit">Confirm</button>
                    </form>
                </div>
            }
        </section>
    )
}

export default PasswordReset;