import React, { useState, useEffect, useRef } from 'react';
import updateChatsInDB from '../API/updateChats';
import deleteSingleChatDB from '../API/deleteSingleChat';
import closeChat from '../API/closeSingleChat';
import reopenChat from '../API/reopenChat';
import { latestVisitorMessage } from '../helpers/latestVisitorMessage';
import SavedRepliesDropdown from './SavedRepliesDropdown';
import './ChatList.css';
import linkifyit from 'linkify-it';
import tlds from 'tlds';

const sendIcon = '/images/icons/icon_send_black_transparent.png';
const searchIcon = '/images/icons/icon-search.png';
const closeIcon = '/images/icons/icon-close.png';

export default function ChatList({ companyName, companyID, socket, chats, setChats, visitorTyping, savedReplies }) {

	const linkify = linkifyit();
	linkify
		.tlds(require('tlds')) // Reload with full tlds list
		.tlds('onion', true) // Add unofficial `.onion` domain
		.add('git:', 'http:') // Add `git:` protocol as "alias"
		.add('ftp:', null) // Disable `ftp:` protocol
		.set({ fuzzyIP: true });

	const [currentConvo, setCurrentConvo] = useState(null);
	const [reply, setReply] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [currentVisitorInfo, setCurrentVisitorInfo] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [activeSearch, setActiveSearch] = useState(false);
	const containerRef = useRef(null);
	const [displayedChatsClosed, setDisplayedChatsClosed] = useState(false);
	const [openClosedChatsCounters, setOpenClosedChatsCounters] = useState({ open: 0, closed: 0 });

	useEffect(() => {
		const element = containerRef.current;
		if (element) {
			element.scrollTop = element.scrollHeight;
		}
	}, [chats]);

	useEffect(() => {
		if (currentConvo) {
			const currentUser = chats.findIndex(client => client.visitorID === currentConvo);
			setCurrentVisitorInfo({
				connected: chats[currentUser].connected ? true : false,
				visitorEmail: chats[currentUser].visitorEmail || 'Site Visitor',
				visitorURL: chats[currentUser].lastViewedURL || 'URL is not provided',
				visitorLocation: (!chats[currentUser].visitorLocation || chats[currentUser].visitorLocation.includes('undefined')) ? 'Location not shared' : chats[currentUser].visitorLocation,
				lastViewdPage: chats[currentUser].lastViewedPage || chats[currentUser].visitorURL,
				visitorConversionRating: chats[currentUser].visitorConversionRating || 1,
			});
		}

		let openChats = 0;
		let closedChats = 0;
		chats.forEach(client => {
			if (client.chatClosed) {
				closedChats++;
			} else {
				openChats++;
			}
		});
		setOpenClosedChatsCounters({ open: openChats, closed: closedChats });
	}, [chats, currentConvo]);

	const selectChat = (which) => {
		setCurrentConvo(which);
		const tempChats = [...chats];
		const clientIndex = tempChats.findIndex(client => client.visitorID === which);
		tempChats[clientIndex].visitorChat.forEach(chat => chat.read = true);
		setChats([...tempChats]);
		updateChatsInDB(companyID, tempChats);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		socket.emit('company message', currentConvo, companyID, reply, currentConvo);
		setReply('');
	};

	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};
	const handleOutsideClick = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const companyIsTyping = () => {
		socket.emit('company typing', currentConvo, companyID);
	};

	const deleteSingleChat = (currentConvo) => {
		// if (window.confirm("Are you sure you want to delete this chat?")) {
		console.log('deleting chat with id:', currentConvo);
		const tempChats = [...chats];
		const clientIndex = tempChats.findIndex(client => client.visitorID === currentConvo);
		tempChats.splice(clientIndex, 1);
		setChats([...tempChats]);
		setCurrentConvo(null);
		setIsOpen(false);
		deleteSingleChatDB(companyID, currentConvo);
		// }
	};

	const closeSingleChat = (currentConvo) => {
		// if (window.confirm("Are you sure you want to close this chat?")) {
		const tempChats = [...chats];
		const clientIndex = tempChats.findIndex(client => client.visitorID === currentConvo);
		tempChats[clientIndex].chatClosed = true;
		setChats([...tempChats]);
		setCurrentConvo(null);
		setIsOpen(false);
		closeChat(companyID, currentConvo);
		// }
	};

	const reopenSingleChat = (currentConvo) => {
		// if (window.confirm("Are you sure you want to reopen this chat?")) {
		const tempChats = [...chats];
		const clientIndex = tempChats.findIndex(client => client.visitorID === currentConvo);
		tempChats[clientIndex].chatClosed = false;
		setChats([...tempChats]);
		setCurrentConvo(null);
		setIsOpen(false);
		reopenChat(companyID, currentConvo);
		// }
	};

	const renderChatList = (chats) => {
		let chatsToRender = [...chats];
		if (activeSearch && searchValue) {
			chatsToRender = chats.filter(chat => {
				const emailMatch = chat.visitorEmail.toLowerCase().includes(searchValue.toLowerCase());
				const chatMatch = JSON.stringify(chat.visitorChat).toLowerCase().includes(searchValue.toLowerCase());
				return emailMatch || chatMatch;
			});
		}

		return chatsToRender.map((client, index) => {
			if (client.chatClosed === displayedChatsClosed) {
				return (
					<div
						className='underlined'
						key={client.visitorID}
						onClick={() => { selectChat(chatsToRender[index].visitorID) }}
						style={currentConvo === client.visitorID ? styles.activeConvo : !client.visitorChat.at(-1).read ? styles.unreadMessages : styles.readMessages}
					>
						<div className='inlineFlexContainer' style={{'alignItems': 'flex-start'}}>
							<p className='boldText bottomSpacer visitorEmail'>{client.visitorEmail || 'Site visitor'}</p>


							<div className='tinyText' >
								{displayedChatsClosed ?
									<span
										onClick={(e) => {
											e.stopPropagation();
											reopenSingleChat(client.visitorID)
										}}
										style={{'marginRight': '0.5em'}}
									>
										Reopen 
									</span> :
									<span
										onClick={(e) => {
											e.stopPropagation();
											closeSingleChat(client.visitorID)
										}}
										style={{'marginRight': '0.5em'}}
									>
										Close 
									</span>}
								<span
									onClick={(e) => {
										e.stopPropagation();
										deleteSingleChat(client.visitorID)
									}}
								>
									Delete
								</span>
							</div>

						</div>


						<p className='tinyText'>{latestVisitorMessage(client.visitorChat.at(-1).message)}</p>
						<p className='tinyText textRightAligned'>{new Date(client.visitorChat.at(-1).timestamp).toLocaleDateString("en-US", { weekday: 'short', year: "numeric", month: "long", day: "numeric", })} {new Date(client.visitorChat.at(-1).timestamp).toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" })}</p>
					</div>
				);
			} else {
				return null;
			}
		});
	};

	const renderCurrentConvo = () => {
		if (currentConvo) {
			const currentUser = chats.findIndex(client => client.visitorID === currentConvo);
			return chats[currentUser].visitorChat.map((chat, index) => {
				const messageHtml = chat.message && linkify.match(chat.message)
					? chat.message.replace(linkify.match(chat.message)[0].raw, (match) =>
						`<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`)
					: chat.message;

				return (
					<div key={index} className={chat.type === 'fromVisitor' ? `messageLeftAligned` : `messageRightAligned`} style={styles.messageContainer}>
						<p className='singleChatMessage' dangerouslySetInnerHTML={{ __html: messageHtml }}></p>
						<span className='tinyText'>{new Date(chat.timestamp).toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "long", day: "numeric", })} {new Date(chat.timestamp).toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute: "2-digit" })}</span>
					</div>
				);
			});
		}
	};

	const setChatsFilter = (filter) => {
		if (filter === 'open') {
			setDisplayedChatsClosed((prevState) => {
				if (prevState !== false) {
					setCurrentConvo(null);
					return false;
				} else {
					return prevState;
				}
			});
		} else {
			setDisplayedChatsClosed((prevState) => {
				if (prevState !== true) {
					setCurrentConvo(null);
					return true;
				} else {
					return prevState;
				}
			});
		}
	};

	const handleRatingChange = (newRating) => {
		const tempChats = [...chats];
		const currentUserIndex = tempChats.findIndex(client => client.visitorID === currentConvo);
		if (currentUserIndex !== -1) {
			tempChats[currentUserIndex].visitorConversionRating = newRating;
			setChats(tempChats);
			updateChatsInDB(companyID, tempChats);
		}
	};

	const renderStars = (rating) => {
		const stars = [];
		for (let i = 1; i <= 3; i++) {
			stars.push(
				<span
					key={i}
					onClick={() => handleRatingChange(i)}
					style={{ cursor: 'pointer', color: i <= rating ? '#e9c504' : '#ccc' }}
				>
					★
				</span>
			);
		}
		return stars;
	};

	return (
		<div className='chatContainer'>
			<section className='chatList'>
				<h1 className='underlined' id='companyName'>{companyName}</h1>
				<p className='underlined tinyText textCentered'>{chats.length} chats in total</p>
				<div className='inlineFlexContainer'>
					<input type="text" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
					<img src={searchIcon} alt='search' style={styles.icon} onClick={() => setActiveSearch(true)} />
					<img
						src={closeIcon}
						alt='close'
						style={{
							...styles.icon,
							...(activeSearch && { backgroundColor: '#ffff64' })
						}}
						onClick={() => { setActiveSearch(false); setSearchValue('') }}
					/>
				</div>
				<div className="chatFilerTabs underlined">
					<div
						className={'' + (!displayedChatsClosed ? "chatFilerTabActive" : "chatFilerTab")}
						onClick={() => setChatsFilter('open')}
					>
						<span className="tinyText">Open chats</span>
						<span className='smallCounterNumber tinyText'>{openClosedChatsCounters.open}</span>
					</div>
					<div
						className={'' + (displayedChatsClosed ? "chatFilerTabActive" : "chatFilerTab")}
						onClick={() => setChatsFilter('closed')}
					>
						<span className="tinyText">Closed chats</span>
						<span className='smallCounterNumber tinyText'>{openClosedChatsCounters.closed}</span>
					</div>
				</div>
				<div style={styles.overflowVisitorsList}>
					{renderChatList(chats)}
				</div>
			</section>

			<section className='singleChat'>
				{currentConvo &&
					<div className='underlined singleChatInfoPanel'>
						<div>
							<p className='boldText'>{currentVisitorInfo.connected ? <span className='greenBullet'></span> : <span className='redBullet'></span>} {currentVisitorInfo.visitorEmail} {visitorTyping === currentConvo && <span className='tinyText italicText boldText'>typing...</span>}</p>
							<p className='tinyText'>{currentVisitorInfo.visitorLocation}, Last viewed: <a href={currentVisitorInfo.visitorURL} target='_blank' rel='noreferrer'>{currentVisitorInfo.lastViewdPage}</a></p>
						</div>
						<div>
							<span style={styles.conversionRating}>{renderStars(currentVisitorInfo.visitorConversionRating)}</span>

							<div ref={dropdownRef} style={styles.dropdownContainer}>
								<button onClick={toggleDropdown} style={styles.dropdownButton}>
									<span className='tinyText' style={{ ...styles.arrow, transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
										▽
									</span>
								</button>
								{isOpen && (
									<div className='tinyText' style={styles.dropdownMenu}>
										{displayedChatsClosed ?
											<div
												onClick={() => reopenSingleChat(currentConvo)}
												style={styles.dropdownItem}
											>
												Reopen chat
											</div> :
											<div
												onClick={() => closeSingleChat(currentConvo)}
												style={styles.dropdownItem}
											>
												Close chat
											</div>}
										<div
											onClick={() => deleteSingleChat(currentConvo)}
											style={styles.dropdownItem}
										>
											Delete chat
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				}

				<div ref={containerRef} style={styles.overflow}>
					<section style={styles.messages}>
						{currentConvo && renderCurrentConvo()}
					</section>
				</div>
				{currentConvo &&
					<form onChange={companyIsTyping} style={styles.form} onSubmit={(e) => e.preventDefault()}>
						<SavedRepliesDropdown companyID={companyID} setReply={setReply} savedReplies={savedReplies} />
						<textarea onChange={companyIsTyping} style={styles.formInput} value={reply} className='chatInput' type="text" placeholder="Type a message" onChange={(e) => setReply(e.target.value)} />
						<img src={sendIcon} alt='send' onClick={reply ? onSubmit : (e) => e.preventDefault()} style={styles.icon} />
					</form>
				}
			</section>
		</div>
	);
}

const styles = {
	button: {
		backgroundColor: 'white',
		border: 'none',
		width: '3em',
		height: '3em'
	},
	form: {
		display: 'flex',
		width: '90%',
		padding: '0.5em',
		boxShadow: '5px 5px 6px 1px rgba(0,0,0, .2)',
		margin: '0 auto',
		alignItems: 'center',
	},
	formInput: {
		width: 'calc(100% - 3em)',
		padding: '.3em',
		textAlign: 'left',
		maxHeight: '6em',
		overflow: 'scroll',
		resize: 'none',
		alignSelf: 'center',
	},
	overflow: {
		overflowY: 'scroll',
		overflowAnchor: 'auto',
		height: '100%',
	},
	messages: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	overflowVisitorsList: {
		overflowY: 'scroll',
		height: '80vh',
		padding: 0,
	},
	messageContainer: {
		margin: '1em',
	},
	unreadMessages: {
		backgroundColor: 'lawngreen',
		padding: '0.5rem 0.5rem 0.2rem'
	},
	readMessages: {
		backgroundColor: 'inherit',
		padding: '0.5rem 0.5rem 0.2rem'
	},
	activeConvo: {
		backgroundColor: '#fffdb1',
		padding: '0.5rem 0.5rem 0.2rem'
	},
	dropdownContainer: {
		position: 'relative',
		display: 'inline-block',
	},
	dropdownButton: {
		backgroundColor: '#fff',
		border: '1px solid #ccc',
		padding: '5px 10px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		top: '100%',
		right: '0px',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
		zIndex: '1000',
		width: 'max-content',
		margin: 0,
	},
	arrow: {
		display: 'inline-block',
		transition: 'transform 0.3s ease',
	},
	dropdownMenu: {
		position: 'absolute',
		top: '100%',
		right: '0',
		width: 'max-content',
		backgroundColor: '#fff',
		border: '1px solid #ccc',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		zIndex: 1000,
	},
	dropdownItem: {
		padding: '10px',
		cursor: 'pointer',
		borderBottom: '1px solid #ccc',
	},
	icon: {
		display: 'block',
		width: '20px',
		selfAlign: 'center',
		marginLeft: '0.5em',
	},
	conversionRating: {
		color: '#e9c504',
		cursor: 'pointer',
		marginRight: '1em',
	}
};
