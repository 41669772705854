import './Dashboard.css'
import subscriptionPlans from '../static/subscriptionPlans'

export default function Dashboard({ userData, chatsNumber }) {
	const { activePlan, companyName, conversations, userName } = userData ? userData : {}
	let emailsCollected = conversations?.filter(convo => convo.visitorEmail.length>0).length
	
	return (
		<>
			{userData ?
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Welcome back, <span className='boldText'>{userName}</span></h1>
					<p>Here you will see the overview of your account.</p>
					<article className='bubbleContainer'>
						<h2>Chats</h2>
						<p>All of your chats: <span className='boldText'>{chatsNumber}</span></p>
						<p>Emails collected: <span className='boldText'>{emailsCollected}</span></p>
					</article>
					<article className='bubbleContainer'>
						<h2>Your team</h2>
						<p>Your organization:<span className='boldText'> {companyName}</span></p>
						<p>Current subscription plan: <span className='boldText'>{subscriptionPlans[activePlan]}</span></p>
					</article>
					<article className='bubbleContainer'>
						<h2>Support</h2>
						<p>We provide support via single point of contact: <a href={`mailto:support@lechat.app?subject=Le Chat customer ticket ${userData?.companyID}`} className='boldText'>support@lechat.app</a></p>
					</article>
				</section> :
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Loading...</h1>
				</section>
			}
		</>
	)
}