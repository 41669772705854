import React, { useState } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import './ImageUpload.css'; 
import { URL } from '../socket';

const ImageUpload = ({ companyID, getUserFromDB }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);

    const onSelectFile = async (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            // Compress and resize the image
            const options = {
                maxWidthOrHeight: 250,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);

                setSelectedImage(compressedFile);
                setOriginalFileName(file.name); // Save the original file name
                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = () => {
                    setPreview(reader.result);
                };
            } catch (error) {
                console.error('Error compressing the image:', error);
            }
        }
    };

    const handleUpload = async () => {
        if (!selectedImage) {
            alert('Please select an image first');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedImage, originalFileName); // Append the file with original name
        formData.append('companyID', companyID); // Append the companyID

        try {
            const response = await axios.post(`${URL}/users/save-avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // console.log('Success:', response.data);

            // Reset the component to its initial state
            setSelectedImage(null);
            setPreview(null);
            setOriginalFileName(null);
            document.getElementById('fileInput').value = '';
            getUserFromDB(companyID);
        } catch (error) {
            console.error('Error uploading the image:', error);
        }
    };

    return (
        <div className="container">
            <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg"
                onChange={onSelectFile}
                style={{ display: 'none' }}
            />
            <button
                type="button"
                onClick={() => document.getElementById('fileInput').click()}
                className="custom-file-upload"
            >
                Choose File
            </button>
            {preview && (
                <div className="preview-container">
                    <img src={preview} alt="Selected" className="preview-image" />
                </div>
            )}
            {preview && <button className="saveButton" onClick={handleUpload}>Upload Image</button>}
        </div>
    );
};

export default ImageUpload;
