// const React = window.React;
// const ReactDOM = window.ReactDOM;
import { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { ui } from './static/ui.js'
// import { v4 as uuidv4 } from 'uuid';
// import validator from 'validator';
// import { socket, URL } from './socket';
// import linkifyit from 'linkify-it';
// import tlds from 'tlds'
// import { ChatForm } from './components/ChatForm';
import './Reset.css'
import './App.css';
import chatIcon from './images/icons/leChat_icon_white_transparent.png'
import closeChatIcon from './images/icons/leChat_cross_white.png'
import leChatLogoCompact from './images/logos/leChat_logo_compact.png'
// const { useState, useEffect } = window.React

// const SERVER_URL = URL;

const LeChat = ({ mainColor, accentColor, avatarImage, welcomeMessage, inputPlaceholder, leaveEmailMessage, buttonLabel, companyName, userName, sendImg, emailPrompt, showLabel }) => {


  // styles customizible by the company
  const styles = {
    customBackgroundAndColorChatHeader: {
      backgroundColor: mainColor,
      color: accentColor,
    },
    customBackgroundAndColorChatBody: {
      backgroundColor: 'white',
      color: 'black',
    },
    chatOpenCloseIcon: {
      boxSizing: 'border-box',
      width: '100% !important',
      height: 'auto !important',
      maxWidth: '100% !important',
      border: 'none !important',
      margin: '0 !important',
      padding: '0 !important',
      display: 'block !important',
    },
    icon: {
      top: window.innerHeight - 70,
      left: window.innerWidth - 70,
    },
    button: {
      height: '1.8em',
      margin: '0.5em',
      // backgroundColor: mainColor,
      color: accentColor,
      alignSelf: 'center',
    },
    form: {
      display: 'flex',
      minHeight: '20%',
      height: '20%',
      margin: '.5em',
      // backgroundColor: mainColor,
      color: accentColor,
    },
  }

  const [widgetOpen, setWidgetOpen] = useState(true);


  return (
    <div className="leChatWidget">




      {/* chat window */}
      {widgetOpen &&
        <div className='chatWindow'>
          <div className='chatContainer' style={styles.customBackgroundAndColorChatBody}>
            <header style={styles.customBackgroundAndColorChatHeader}>
              <div className='mobileOnly'><span style={styles.customBackgroundAndColorChatHeader}>X</span></div>
              <article style={styles.customBackgroundAndColorChatHeader}>
                <span className='companyAvatrContainer'>
                  {avatarImage && <img src={avatarImage} alt='avatar' />}
                  <span className='connectedOnline'></span>
                </span>
                <h1 style={styles.customBackgroundAndColorChatHeader}>{companyName}&nbsp;</h1>
                <span style={styles.customBackgroundAndColorChatHeader} className='tinyText'>{userName && `> ${userName}`}</span>
              </article>
              {!emailPrompt&&<p style={styles.customBackgroundAndColorChatHeader}>{welcomeMessage}</p>}
              {emailPrompt&&<form style={styles.customBackgroundAndColorChatHeader} className='askEmailContainer' >
                <p style={styles.customBackgroundAndColorChatHeader} className='tinyText'>{leaveEmailMessage}</p>
                <input type='email' required />
                <button>Save</button>
              </form>}
            </header>
            <div className='chatWindowMessages' style={styles.customBackgroundAndColorChatBody}>
              <section className='chatConversation' style={styles.customBackgroundAndColorChatBody}>
                <div className={`chatConvoItem user`} style={styles.customBackgroundAndColorChatBody} >
                  <div className='chatConvoItemAvatar' style={styles.customBackgroundAndColorChatBody}>
                    <img style={styles.customBackgroundAndColorChatBody} className='chatAvatars' src={avatarImage} alt='avatar' />
                  </div>
                  <div className='chatConvoItemContent' style={styles.customBackgroundAndColorChatBody}>
                    {/* <div className='chatConvoItemContentName' style={styles.customBackgroundAndColorChatBody}>{item.name}</div> */}
                    <div className='chatConvoItemContentMessage'>Hello, how can we help you?</div>
                  </div>
                </div>
              </section>
            </div>

            {/* <ChatForm
              bkgrndCol={styles.customBackgroundAndColorChatBody.backgroundColor}
              clr={styles.customBackgroundAndColorChatBody.color}
              chatConvo={chatConvo}
              setChatConvo={setChatConvo}
              companyName={companyName}
              userToken={userToken}
              socket={socket}
              setCompanyOnline={setCompanyOnline} /> */}
            <form style={styles.form}>
              <textarea placeholder={inputPlaceholder} disabled />
              <img style={styles.button} src={sendImg} />
            </form>

            {showLabel && <div className='poweredBy' style={styles.customBackgroundAndColorChatBody}>
              <a href='https://lechat.app/' target='_blank' rel='noreferrer' style={styles.customBackgroundAndColorChatBody}>
                <p style={styles.customBackgroundAndColorChatBody}>powered by</p>
              </a>
              <a href='https://lechat.app/' target='_blank' rel='noreferrer' style={styles.customBackgroundAndColorChatBody}>
                <img style={styles.customBackgroundAndColorChatBody} src={leChatLogoCompact} alt='LeChat logo' className='littleLogo' />
              </a>

            </div>}
          </div>

        </div>
      }
      <br />
      {/* floating widget icon */}
      <div className='widgetIcon' style={{ ...styles.customBackgroundAndColorChatHeader, ...styles.icon }}>
        {!widgetOpen && <img src={chatIcon} className='leChatWidgetIcon' style={{ ...styles.chatOpenCloseIcon, ...styles.customBackgroundAndColorChatHeader }} alt='Le Chat icon' onClick={() => setWidgetOpen(!widgetOpen)} />}
        {widgetOpen && <img src={closeChatIcon} className='leChatWidgetIcon' style={{ ...styles.chatOpenCloseIcon, ...styles.customBackgroundAndColorChatHeader }} alt='Le Chat icon' onClick={() => setWidgetOpen(!widgetOpen)} />}
        {/*<button onClick={()=>setWidgetOpen(!widgetOpen)}>Chat</button>*/}
      </div>

    </div>
  );
}


export default LeChat;
