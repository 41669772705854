import { NavLink } from "react-router-dom";
import './SideBar.css'

const chatsBlack = '/images/icons/icon_chats_black.png'
// const chatsWhite = '/images/icons/icon_chats_white.png'
const usersBlack = '/images/icons/icon_users_black.png'
// const usersWhite = '/images/icons/icon_users_white.png'
const accountBlack = '/images/icons/icon-account-black.png'
// const accountWhite = '/images/icons/icon-account-white.png'
const autoReplyBlack = '/images/icons/icon-autoreply-black.png'
// const autoReplyWhite = '/images/icons/icon-autoreply-white.png'
const playbookBlack = '/images/icons/icon-playbook-black.png'
// const playbookWhite = '/images/icons/icon-playbook-white.png'
const savedRepliesBlack = '/images/icons/icon-saved-replies-black.png'
// const savedRepliesWhite = '/images/icons/icon-saved-replies-white.png'
const settingsBlack = '/images/icons/icon-settings-black.png'
// const settingsWhite = '/images/icons/icon-settings-white.png'
const installBlack = '/images/icons/icon_integration_black.png'

const logo = '/leChat_logo_compact.png'


export default function SideBar({ unreadChats }) {
	const styles = {
		icons: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			height: '100%',
			padding:'0.5em',
		},
		image: {
			margin: '1em auto 0.1em',
			display: 'block',
			width: '25px',
		},
		caption: {
			fontSize: 'fit-content',
			textAlign: 'center',
			marginBottom:'2em',
			color: 'black',
			breakWord: 'break-all'
		},
		logo: {
			display: 'inline-block',
			height: 'auto',
			margin: '1em 1px',
			width: 'calc(100% - 4px)'
		}
	}

	return (
		<div className='verticalSideBar' style={styles.icons}>
			<div>
				<NavLink to={"/"}>
					<img style={styles.logo} src={logo} alt='Le Chat Logo' title='Home' />
				</NavLink>

				<NavLink to={"/chats"} className='positioningContext'>
					<img style={styles.image} src={chatsBlack} title='Conversations' alt='Conversations' />
					<p className='tinyText textCentered' style={styles.caption}>Chats</p>
					{unreadChats ? <div className='unreadChatsIndicator'></div> : null}
				</NavLink>

				<NavLink to={"/visitors"} className='positioningContext'>
					<img style={styles.image} src={usersBlack} title='Visitors' alt='Visitors' />
					<p className='tinyText textCentered' style={styles.caption}>Visitors</p>
				</NavLink>

				<NavLink to={"/savedreplies"} className='positioningContext'>
					<img style={styles.image} src={savedRepliesBlack} title='Saved replies' alt='Saved replies' />
					<p className='tinyText textCentered' style={styles.caption}>Saved replies</p>
				</NavLink>

				<NavLink to={"/autoreplies"} className='positioningContext'>
					<img style={styles.image} src={autoReplyBlack} title='Auto replies' alt='Auto replies' />
					<p className='tinyText textCentered' style={styles.caption}>Auto replies</p>
				</NavLink>

				<NavLink to={"/playbooks"} className='positioningContext'>
					<img style={styles.image} src={playbookBlack} title='Playbooks' alt='Playbooks' />
					<p className='tinyText textCentered' style={styles.caption}>Playbooks</p>
				</NavLink>


			</div>
			<div>

				<NavLink to={"/installation"} className='positioningContext'>
					<img style={styles.image} src={installBlack} title='Installation' alt='Installation' />
					<p className='tinyText textCentered' style={styles.caption}>Install widget</p>
				</NavLink>

				<NavLink to={"/settings"} className='positioningContext'>
					<img style={styles.image} src={settingsBlack} title='Settings' alt='Settings' />
					<p className='tinyText textCentered' style={styles.caption}>Settings</p>
				</NavLink>

				<NavLink to={"/account"} className='positioningContext'>
					<img style={styles.image} src={accountBlack} title='Account' alt='Account' />
					<p className='tinyText textCentered' style={styles.caption}>Account</p>
				</NavLink>
			</div>
		</div>
	)


}