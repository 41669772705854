// SavedRepliesDropdown.js
import { useState, useRef, useEffect } from 'react';
import './SavedRepliesDropdown.css';

const savedReply = '/images/icons/icon-saved-replies-black.png';

export default function SavedRepliesDropdown({ companyID, setReply, savedReplies }) {
    const [isOpen, setIsOpen] = useState(false);
    const [replies, setReplies] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setReplies(savedReplies.filter(reply => reply.enable))
    }, [companyID, savedReplies]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleReplyClick = (replyMessage) => {
        setReply(replyMessage);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className='dropdownContainer'>
            <button onClick={toggleDropdown} className='dropdownButton'>
                <img src={savedReply} alt='dropdown' className='dropdownIcon' />
            </button>
            {isOpen && (
                <div className='dropdownMenu'>
                    {replies.map(reply => (
                        <div
                            key={reply.replyName}
                            className='dropdownItem'
                            onClick={() => handleReplyClick(reply.replyMessage)}
                        >
                            {reply.replyMessage}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
