import LeChatLogo from "../assets/leChat_logo.png"

function CenteredLogo() {
    return (
        <a href='https://lechat.app'><img src={LeChatLogo} alt="Le Chat Logo" style={styles.logo} /></a>
    );
}

export default CenteredLogo;

const styles = {
    logo: {
        display: 'block',
        margin: '0 auto',
        width: '200px',
        marginBottom: 'calc(5vh - 1em)'
    }
}