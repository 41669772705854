import axios from 'axios'
import { useState, useEffect } from 'react'
import './Account.css'
import { URL } from '../socket'
import { useNavigate } from 'react-router-dom'

import trialPeriodEnd from '../helpers/trialPeriodEnd'

import subscriptionPlans from '../static/subscriptionPlans'
import JustChat from '../subscriptions/JustChat'
import LeChat from '../subscriptions/LeChat'

export default function Account({ companyID, logout, setUser, user }) {
	const navigate = useNavigate()

	const [account, setAccount] = useState(user)
	const [updateAccount, setUpdateAccount] = useState(user)
	const [modifiedAccountCompany, setModifiedAccountCompany] = useState(false)
	const [modifiedAccountUser, setModifiedAccountUser] = useState(false)
	const [modifiedAccountSubscription, setModifiedAccountSubscription] = useState(false)
	const trialPeriod = 2592000000; // 30 days in milliseconds
	// get company info form the db 

	// check if url contains queries whic could be either of this type
	// ?success=true&session_id=cs_test_a19agnAPYZEQULkGIvee7MASOeSsLqhQtuYeZb6W3fNHIRwUE5UcPwMQVC
	// or of this type:
	// /?canceled=true

	// get user from teh DB, but maybe rely on user form props instead? 
	// useEffect(() => {
	// 	axios.get(`${URL}/users/${companyID}`)
	// 		.then(response => {
	// 			setAccount(response.data.data)
	// 			setUpdateAccount(response.data.data);
	// 		})
	// 		.catch(error => {
	// 			// handle the error
	// 			console.error(error);
	// 		});
	// }, [companyID])

	useEffect(() => {
		setAccount(user)
		setUpdateAccount(user)
	}, [user])



	const handleCompanyChange = (e) => {
		setModifiedAccountCompany(true);
		setUpdateAccount((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	const handleUserChange = (e) => {
		setModifiedAccountUser(true);
		setUpdateAccount((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	const handleSubscriptionChange = (e) => {
		setModifiedAccountSubscription(true);
		setUpdateAccount((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	const cancelCompanyChanges = () => {
		setModifiedAccountCompany(false);
		setUpdateAccount({ ...updateAccount, companyID: account.companyID, companyName: account.companyName, companyVAT: account.companyVAT, companyAddress: account.companyAddress, companyCity: account.companyCity, companyCountry: account.companyCountry, companyPostcode: account.companyPostcode });
	}
	const cancelUserChanges = () => {
		setModifiedAccountUser(false);
		setUpdateAccount({ ...updateAccount, userID: account.userID, userEmail: account.userEmail, userPassword: account.userPassword, userSignupDate: account.userSignupDate, userName: account.userName, userLastname: account.userLastname, avatar: account.avatar });
	}
	const cancelSubscriptionChanges = () => {
		setModifiedAccountSubscription(false);
		setUpdateAccount({ ...updateAccount, activePlan: account.activePlan, discounts: account.discounts, activeSubscription: account.activeSubscription });
	}


	const saveCompanyChanges = () => {
		// update "account" in state and in the db
		setAccount((currentAccount) => ({ ...currentAccount, companyID: updateAccount.companyID, companyName: updateAccount.companyName, companyVAT: updateAccount.companyVAT, companyAddress: updateAccount.companyAddress, companyCity: updateAccount.companyCity, companyCountry: updateAccount.companyCountry, companyPostcode: updateAccount.companyPostcode }));
		try {
			axios.put(`${URL}/users/update`, { ...account, companyID: updateAccount.companyID, companyName: updateAccount.companyName, companyVAT: updateAccount.companyVAT, companyAddress: updateAccount.companyAddress, companyCity: updateAccount.companyCity, companyCountry: updateAccount.companyCountry, companyPostcode: updateAccount.companyPostcode })
				.then(response => {
					// handle the response data
					setModifiedAccountCompany(false);
				})
				.catch(error => {
					// handle the error
					console.error(error);
				});
		}
		catch (error) {
			// handle the error
			console.error(error);
		}
	}
	const saveUserChanges = () => {
		// update "account" in state and in the db
		setAccount((currentAccount) => ({ ...currentAccount, userID: updateAccount.userID, userEmail: updateAccount.userEmail, userPassword: updateAccount.userPassword, userSignupDate: updateAccount.userSignupDate, userName: updateAccount.userName, userLastname: updateAccount.userLastname, avatar: updateAccount.avatar }));
		try {
			axios.put(`${URL}/users/update`, { ...account, userID: updateAccount.userID, userEmail: updateAccount.userEmail, userPassword: updateAccount.userPassword, userSignupDate: updateAccount.userSignupDate, userName: updateAccount.userName, userLastname: updateAccount.userLastname, avatar: updateAccount.avatar })
				.then(response => {
					// handle the response data
					setModifiedAccountUser(false);
				})
				.catch(error => {
					// handle the error
					console.error(error);
				});
		}
		catch (error) {
			// handle the error
			console.error(error);
		}
	}
	const saveSubscriptionChanges = () => {
		// update "account" in state and in the db
		setAccount((currentAccount) => ({ ...currentAccount, activePlan: updateAccount.activePlan, discounts: updateAccount.discounts, activeSubscription: updateAccount.activeSubscription }));
		try {
			axios.put(`${URL}/users/update`, { ...account, activePlan: updateAccount.activePlan, discounts: updateAccount.discounts, activeSubscription: updateAccount.activeSubscription })
				.then(response => {
					// handle the response data
					setModifiedAccountSubscription(false);
				})
				.catch(error => {
					// handle the error
					console.error(error);
				});
		}
		catch (error) {
			// handle the error
			console.error(error);
		}
	}

	const closeSession = () => {
		if (window.confirm("Are you sure you want to log out?")) {
			logout();
			navigate('/')
		}
	}
	const resetPassword = () => {
		if (window.confirm("Log out and reset password?")) {
			logout();
			navigate('/reset-password')
		}
	}
	const deleteAccount = () => {
		if (window.confirm("Are you sure you want to delete your account and all your data?")) {
			axios.delete(`${URL}/users/delete/${companyID}`)
				.then
				(response => {
					alert('Account deleted.')
					logout();
					navigate('/')
				})
				.catch(error => {
					console.error(error);
				});
		}
	}




	return (
		<>
			{companyID ? (
				<section className='outlined homeContainer' id='account'>
					<h1 className='underlined'>Your account</h1>
					<p>Here you can work with your account.</p>

					<article className='bubbleContainer'>
						<h2>Your subscription</h2>
						<div className='bubble'>
							<p>
								Your current plan is <span className='boldText'>{subscriptionPlans[account?.activePlan]} / <span className='boldText'>{account?.subscription?.status || (account?.activeSubscription ? 'active' : 'inactive')}</span>
								</span>
							</p>
							<p>
								Company ID: {updateAccount?.companyID}
							</p>
							{account?.activePlan !== 0 && <p>Next billing cycle: {new Date(account?.subscription?.current_period_end * 1000).toLocaleDateString()}</p>}

							{account?.activePlan === 0 && <p> Your trial period ends on {trialPeriodEnd(account.userSignupDate, trialPeriod)}</p>}

							<div className='inlineFlexContainer' style={{justifyContent:'flex-start'}}>
								{account?.userEmail && <JustChat
									userEmail={account?.userEmail}
									setUser={setUser}
									subscriptionPlan={updateAccount?.activePlan}
									customerID={account?.subscription?.customer}
									subscriptionStatus={account?.activeSubscription} />}

								{account?.userEmail && <LeChat
									userEmail={account?.userEmail}
									setUser={setUser}
									subscriptionPlan={updateAccount?.activePlan}
									customerID={account?.subscription?.customer}
									subscriptionStatus={account?.activeSubscription} />}
							</div>

							{/* <p>
								Discount coupons <br /><input type="text" name="discounts" value={updateAccount?.discounts} onChange={handleSubscriptionChange} placeholder='Please enter a coupon code' />
							</p> */}
							{/* <p>
								Subscription status is {updateAccount?.activeSubscription ? <span className='boldText'>active</span> : <span className='boldText'>inactive</span>}
							</p> */}

							{/* <button className='cancelButton' onClick={cancelSubscriptionChanges} disabled={!modifiedAccountSubscription}>Cancel</button>
							<button className='saveButton' onClick={saveSubscriptionChanges} disabled={!modifiedAccountSubscription}>Save</button> */}
						</div>
					</article>

					{/* <article className='bubbleContainer'>
						<h2>Your company info</h2>
						<div className='bubble'>
							<p>
								Company ID: {updateAccount?.companyID}
							</p>
							<p>
								Company legal name <br /><input type="text" name="companyBillingName" value={updateAccount?.companyBillingName} onChange={handleCompanyChange} />
							</p>
							<p>
								Company tax ID <br /><input type="text" name="companyVAT" value={updateAccount?.companyVAT} onChange={handleCompanyChange} />
							</p>
							<p>
								Address <br /><input type="text" name="companyAddress" value={updateAccount?.companyAddress} onChange={handleCompanyChange} />
							</p>
							<p>
								City <br /><input type="text" name="companyCity" value={updateAccount?.companyCity} onChange={handleCompanyChange} />
							</p>
							<p>
								Country <br /><input type="text" name="companyCountry" value={updateAccount?.companyCountry} onChange={handleCompanyChange} />
							</p>
							<p>
								Postcode <br /><input type="text" name="companyPostcode" value={updateAccount?.companyPostcode} onChange={handleCompanyChange} />
							</p>

							<div className='buttonContainer'>
								<button className='cancelButton' onClick={cancelCompanyChanges} disabled={!modifiedAccountCompany}>Cancel</button>
								<button className='saveButton' onClick={saveCompanyChanges} disabled={!modifiedAccountCompany}>Save</button>
							</div>
						</div>
					</article> */}

					<article className='bubbleContainer'>
						<h2>Your user info</h2>
						<div className='bubble'>
							{/* <p>
								User ID: {updateAccount?.userID}
							</p> */}
							<p>
								Member since {new Date(updateAccount?.userSignupDate).toLocaleDateString()}
							</p>
							<p>
								Email for login and notifications: <span className='boldText'>{updateAccount?.userEmail}</span>
							</p>
							{updateAccount?.userName && <p>
								Name <br /><input type="text" name="userName" value={updateAccount?.userName} onChange={handleUserChange} />
							</p>}
							{updateAccount?.userLastname && <p>
								Lastname <br /><input type="text" name="userLastname" value={updateAccount?.userLastname} onChange={handleUserChange} />
							</p>}
							{/* <p>
								Avatar <br /><input type="text" name="avatar" value={updateAccount?.avatar} onChange={handleUserChange} />
							</p> */}

							<button className='cancelButton' onClick={cancelUserChanges} disabled={!modifiedAccountUser}>Cancel</button>
							<button className='saveButton' onClick={saveUserChanges} disabled={!modifiedAccountUser}>Save</button>
						</div>
					</article>



					<article className='bubbleContainer'>
						<h2>Session</h2>
						<div className='bubble'>
							<button className='cancelButton' onClick={resetPassword}>Reset password</button>
							<button className='cancelButton' onClick={closeSession}>Log out</button>
							<button className='dangerButton' onClick={deleteAccount}>Delete account and data</button>
						</div>
					</article>

				</section>
			) : (
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Loading...</h1>
				</section>
			)}
		</>
	);
}