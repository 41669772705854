import axios from 'axios'
import { URL } from '../socket';

const reopenChat = async (companyID,currentConvo) => {
	try {
		const response = await axios.post(`${URL}/chats/reopenhat`, {companyID,chatID:currentConvo})
		if(response.data.ok){
		}
		return
	}
	catch(e){
		console.log({e})
	}
}

export default reopenChat
