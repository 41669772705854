import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Settings.css'
import '../App.css'
import { URL } from '../socket'
import ChatWidgetPreview from '../components/ChatWidgetPreview'
import ImageUpload from '../components/ImageUpload'
const defaultWelcomeMessage = 'Hi there! Respond below and one of our team members will assist you as soon as possible.'
const defaultInputPlaceholder = 'Type your message here...'
const defaultLeaveEmailMessage = 'Please leave your email so we could contact you in case we will disconnect.'
const defaultSaveEmailButtonLabel = 'Save'
const defaultPrompts = { welcomeMessage: defaultWelcomeMessage, inputPlaceholder: defaultInputPlaceholder, leaveEmailMessage: defaultLeaveEmailMessage, saveEmailButtonLabel: defaultSaveEmailButtonLabel }

export default function Settings({ companyID, user, getUserFromDB }) {
	const [mainColor, setMainColor] = useState('#000000')
	const [accentColor, setAccentColor] = useState('#c61f1f')
	const [updateMainColor, setUpdateMainColor] = useState('#000000')
	const [updateAccentColor, setUpdateAccentColor] = useState('#c61f1f')
	const [modifiedColor, setModifiedColor] = useState(false)

	const [account, setAccount] = useState({})
	const [updateAccount, setUpdateAccount] = useState({})
	const [modifiedAccountCompany, setModifiedAccountCompany] = useState(false)

	const [accountPrompts, setAccountPrompts] = useState({})
	const [updateAccountPrompts, setUpdateAccountPrompts] = useState({ welcomeMessage: 'Hi there! Respond below and one of our team members will assist you as soon as possible.', inputPlaceholder: 'Type your message here...', leaveEmailMessage: 'Please leave your email so we could contact you in case we will disconnect.', saveEmailButtonLabel: 'Save' })
	const [modifiedAccountPrompts, setModifiedAccountPrompts] = useState(false)
	const [showLabel, setShowLabel] = useState(null)

	useEffect(() => {
		if (user) {
			setUpdateAccount(user)
			setAccount(user)
			setUpdateMainColor(user.mainColor)
			setUpdateAccentColor(user.accentColor)
			setMainColor(user.mainColor)
			setAccentColor(user.accentColor)
			setAccountPrompts({
				welcomeMessage: user.welcomeMessage,
				inputPlaceholder: user.inputPlaceholder,
				leaveEmailMessage: user.leaveEmailMessage,
				saveEmailButtonLabel: user.saveEmailButtonLabel
			})
			setUpdateAccountPrompts({
				welcomeMessage: user.welcomeMessage,
				inputPlaceholder: user.inputPlaceholder,
				leaveEmailMessage: user.leaveEmailMessage,
				saveEmailButtonLabel: user.saveEmailButtonLabel
			})
			setShowLabel(user.showLabel)
		}
	}, [user])
	const handleCompanyChange = (e) => {
		setModifiedAccountCompany(true);
		setUpdateAccount((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	const cancelCompanyChanges = () => {
		setModifiedAccountCompany(false);
		setUpdateAccount({ ...updateAccount, companyID: account.companyID, companyName: account.companyName, companyVAT: account.companyVAT, companyAddress: account.companyAddress, companyCity: account.companyCity, companyCountry: account.companyCountry, companyPostcode: account.companyPostcode, userName: account.userName, avatar: account.avatar });
	}

	const saveCompanyChanges = () => {
		// update "account" in state and in the db
		setAccount((currentAccount) => ({ ...currentAccount, companyID: updateAccount.companyID, companyName: updateAccount.companyName, companyVAT: updateAccount.companyVAT, companyAddress: updateAccount.companyAddress, companyCity: updateAccount.companyCity, companyCountry: updateAccount.companyCountry, companyPostcode: updateAccount.companyPostcode }));
		try {
			axios.put(`${URL}/users/update`, { ...account, companyID: updateAccount.companyID, companyName: updateAccount.companyName, userName: updateAccount.userName, companyVAT: updateAccount.companyVAT, companyAddress: updateAccount.companyAddress, companyCity: updateAccount.companyCity, companyCountry: updateAccount.companyCountry, companyPostcode: updateAccount.companyPostcode })
				.then(response => {
					// handle the response data
					setModifiedAccountCompany(false);
				})
				.catch(error => {
					// handle the error
					console.error(error);
				});
		}
		catch (error) {
			// handle the error
			console.error(error);
		}
	}

	const handleAccountPrompts = (e) => {
		setModifiedAccountPrompts(true);
		setUpdateAccountPrompts((prevState) => ({ ...prevState, [e.target.name]: e.target.value || defaultPrompts[e.target.name] }));
	}
	const cancelAccountPrompts = () => {
		setModifiedAccountPrompts(false);
		setUpdateAccountPrompts({ welcomeMessage: accountPrompts.welcomeMessage, inputPlaceholder: accountPrompts.inputPlaceholder, leaveEmailMessage: accountPrompts.leaveEmailMessage, saveEmailButtonLabel: accountPrompts.saveEmailButtonLabel });
	}
	const saveAccountPrompts = () => {
		// update "account" in state and in the db
		setAccount((currentAccountPrompts) => ({ ...currentAccountPrompts, welcomeMessage: updateAccountPrompts.welcomeMessage || defaultWelcomeMessage, inputPlaceholder: updateAccountPrompts.inputPlaceholder || defaultInputPlaceholder, leaveEmailMessage: updateAccountPrompts.leaveEmailMessage || defaultLeaveEmailMessage, saveEmailButtonLabel: updateAccountPrompts.saveEmailButtonLabel || defaultSaveEmailButtonLabel }));
		try {
			axios.put(`${URL}/users/update`, { ...account, welcomeMessage: updateAccountPrompts.welcomeMessage || defaultWelcomeMessage, inputPlaceholder: updateAccountPrompts.inputPlaceholder || defaultInputPlaceholder, leaveEmailMessage: updateAccountPrompts.leaveEmailMessage || defaultLeaveEmailMessage, saveEmailButtonLabel: updateAccountPrompts.saveEmailButtonLabel || defaultSaveEmailButtonLabel })
				.then(response => {
					// handle the response data
					setModifiedAccountPrompts(false);
				})
				.catch(error => {
					// handle the error
					console.error(error);
				});
		}
		catch (error) {
			// handle the error
			console.error(error);
		}
	}



	const saveColors = () => {
		axios.post(`${URL}/service/save-colors`, { companyID, updateMainColor, updateAccentColor })
			.then(response => {
				alert("Colors saved!")
				setModifiedColor(false)
			})
			.catch(error => {
				alert("Error saving colors")
			});
	}

	useEffect(() => {
		const saveShowLabel = () => {
			axios.post(`${URL}/service/save-show-label`, { companyID, showLabel })
				.then(response => {
					// alert("Label visibility saved!")
				})
				.catch(error => {
					// console.log(error)
					// alert("Error saving label visibility")
				});
		}
		if (showLabel !== null) {
			saveShowLabel()
		}
	}, [showLabel])


	const cancelColorChanges = () => {
		setModifiedColor(false)
		setUpdateMainColor(mainColor)
		setUpdateAccentColor(accentColor)
	}


	return (
		<>
			{companyID ?
				<section className='outlined homeContainer' id='settingsView'>
					<h1 className='underlined'>Settings panel</h1>
					<p>Le Chat v3.1</p>
					<article className='bubbleContainer'>
						<h2> Widget preview</h2>
						<div className='grid2'>
							<div>
								<p className='bottomSpacer'>Widget with visitor email already submitted</p>
								<div className='widgetPreview'>
									<ChatWidgetPreview emailPrompt={false} mainColor={updateMainColor} accentColor={updateAccentColor} avatarImage={updateAccount?.avatar} buttonLabel={'save'} companyName={updateAccount?.companyName} userName={updateAccount?.userName} welcomeMessage={updateAccountPrompts.welcomeMessage} leaveEmailMessage={updateAccountPrompts.leaveEmailMessage} inputPlaceholder={updateAccountPrompts.inputPlaceholder} showLabel={showLabel} />
								</div>
							</div>
							<div>
								<p className='bottomSpacer'>Widget asking for visitor email</p>
								<div className='widgetPreview'>
									<ChatWidgetPreview emailPrompt={true} mainColor={updateMainColor} accentColor={updateAccentColor} avatarImage={updateAccount?.avatar} buttonLabel={'save'} companyName={updateAccount?.companyName} userName={updateAccount?.userName} welcomeMessage={updateAccountPrompts.welcomeMessage} leaveEmailMessage={updateAccountPrompts.leaveEmailMessage} inputPlaceholder={updateAccountPrompts.inputPlaceholder} showLabel={showLabel} />
								</div>
							</div>
						</div>
					</article>
					<article className='bubbleContainer'>
						<h2>Appearance</h2>
						<div className='bubble'>
							<p>Choose the main color of the chat </p>
							<input type="color" value={updateMainColor} onChange={(e) => { setModifiedColor(true); setUpdateMainColor(e.target.value) }} />

							<p>Choose the accent color of the chat</p>
							<input type="color" value={updateAccentColor} onChange={(e) => { setModifiedColor(true); setUpdateAccentColor(e.target.value) }} />
							<div className='buttonContainer'>
								<button className='cancelButton' onClick={cancelColorChanges} disabled={!modifiedColor}>Cancel</button>
								<button className='saveButton' onClick={saveColors} disabled={!modifiedColor}>Save colors</button>
							</div>

						</div>
					</article>
					<article className='bubbleContainer'>
						<h2>Le Chat widget info</h2>
						<p><span className={'bottomSpacer'}>This will be visible in the chat widget. Leave empty to remove it from the widget.</span></p>
						<div className='bubble'>
							<p>
								Company name <br /><input type="text" name="companyName" value={updateAccount?.companyName} onChange={handleCompanyChange} />
							</p>
							<p>
								Operator's Name <br /><input type="text" name="userName" value={updateAccount?.userName} onChange={handleCompanyChange} />
							</p>
							<p>Current avatar</p>
							<img src={user?.avatar} alt='avatar' className='avatarPreview bottomSpacer' />
							<p>
								Avatar URL<br />
								<input type="text" name="avatar" value={updateAccount?.avatar} onChange={handleCompanyChange} />
								<br />or upload file
							</p>
							<ImageUpload companyID={companyID} getUserFromDB={getUserFromDB} />
							<div className='buttonContainer'>
								<button className='cancelButton' onClick={cancelCompanyChanges} disabled={!modifiedAccountCompany}>Cancel</button>
								<button className='saveButton' onClick={saveCompanyChanges} disabled={!modifiedAccountCompany}>Save</button>
							</div>
						</div>
					</article>

					{/* article for updating welcomeMessage, inputPlaceholder, leaveEmailMessage */}
					<article className='bubbleContainer'>
						<h2>Prompts and placeholders</h2>
						<p><span className={'bottomSpacer'}>This will be visible in the chat widget. Leave empty to revert to default messages.</span></p>
						<div className='bubble'>
							<p>
								Welcome message <br /><textarea type="text" name="welcomeMessage" value={updateAccountPrompts?.welcomeMessage} onChange={handleAccountPrompts} />
							</p>
							<p>
								Input placeholder <br /><textarea type="text" name="inputPlaceholder" value={updateAccountPrompts?.inputPlaceholder} onChange={handleAccountPrompts} />
							</p>
							<p>
								Leave email message <br /><textarea type="text" name="leaveEmailMessage" value={updateAccountPrompts?.leaveEmailMessage} onChange={handleAccountPrompts} />
							</p>
							<p>
								Save visitor's email button label <br /><input type="text" name="saveEmailButtonLabel" value={updateAccountPrompts?.saveEmailButtonLabel} onChange={handleAccountPrompts} />
							</p>
							<div className='buttonContainer'>
								<button className='cancelButton' onClick={cancelAccountPrompts} disabled={!modifiedAccountPrompts}>Cancel</button>
								<button className='saveButton' onClick={saveAccountPrompts} disabled={!modifiedAccountPrompts}>Save</button>
							</div>
						</div>
					</article>

					{ (showLabel !== null && user?.activePlan>1) &&<article className='bubbleContainer'>
						<h2>"Powered by LeChat" label</h2>
						<div className='bubble'>
							<p>"Powered by Le Chat" label is {showLabel ? 'displayed' : 'hidden'} </p>
							<input type="checkbox" value={showLabel} onClick={() => setShowLabel((currentLable) => { return !currentLable })} />
							<label> {showLabel ? 'hide' : 'show'}</label>
						</div>
					</article>}

				</section> :
				<section className='outlined homeContainer'>
					<h1 className='underlined'>Loading...</h1>
				</section>
			}
		</>
	)
}